import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { PageModel } from 'client/data/models/page';
import { InventoryEntities } from 'client/data/models/inventory';
import { DrawerV2 } from 'site-modules/shared/components/drawer/drawer-v2';
import { WhiteBackButton } from 'site-modules/shared/components/lead-form/lead-form-drawer/close-buttons/white-back-button/white-back-button';
import { CTAButtonAndFormRender } from './contact-dealer-cta-render';

import './contact-dealer-srp.scss';

export function ContactDealerSrpUI({
  tabIndex,
  vinData,
  rootClassName,
  featureFlags,
  pathname,
  isMobile,
  isStickySection,
  radius,
  buttonColor,
  isNational,
  isNonTMVDealer,
  showLeasePayments,
  isPagePrimaryButton,
  tcpaParentDealershipName,
  isSimilarCards,
  ariaLabel,
  isDealerSrp,
  showInTransit,
  photoUrl,
  isInStockOnline,
  isShop3181Chal,
}) {
  const ctasOptions = {
    featureFlags,
    showLeasePayments,
    pathname,
    radius,
    skipDRBtn: isNonTMVDealer,
    tcpaParentDealershipName,
    isLeasePaymentEnabled:
      !!get(vinData, 'prices.tmv', 0) &&
      !!get(vinData, 'prices.estimateLeasePromise.monthlyPayment', 0) &&
      !!get(vinData, 'vehicleInfo.styleInfo.styleId'),
    isSrp: true,
    isDealerSrp,
    showInTransit,
    photoUrl,
    isInStockOnline,
    isShop3181Chal,
  };

  const leadFormDrawerConfig = {
    drawerContentClassName: 'bg-cool-gray-90',
    closeBtnComponent: WhiteBackButton,
    containerClassName: 'pos-st p-0',
    isLFComponentContainerFluid: true,
    drawerContentWrapperClassName: 'px-1 px-md-2_5',
    className: 'srp-lead-form',
    drawerComponent: DrawerV2,
  };

  return (
    <CTAButtonAndFormRender
      tabIndex={tabIndex}
      isMobile={isMobile}
      isStickySection={isStickySection}
      vinData={vinData}
      ctasOptions={ctasOptions}
      isNational={isNational}
      rootClassName={rootClassName}
      buttonClassName={buttonColor}
      isPagePrimaryButton={isPagePrimaryButton}
      isSimilarCards={isSimilarCards}
      ariaLabel={ariaLabel}
      isDealerSrp={isDealerSrp}
      isSrp
      leadFormDrawerConfig={leadFormDrawerConfig}
    />
  );
}

ContactDealerSrpUI.propTypes = {
  tabIndex: PropTypes.number,
  vinData: InventoryEntities.InventoryVin,
  isMobile: PropTypes.bool,
  isStickySection: PropTypes.bool,
  rootClassName: PropTypes.string,
  featureFlags: PropTypes.shape({}),
  pathname: PropTypes.string,
  radius: PropTypes.number,
  isNational: PropTypes.bool,
  buttonColor: PropTypes.string,
  isNonTMVDealer: PropTypes.bool,
  showLeasePayments: PropTypes.bool,
  isPagePrimaryButton: PropTypes.bool,
  tcpaParentDealershipName: PropTypes.string,
  isSimilarCards: PropTypes.bool,
  ariaLabel: PropTypes.string,
  isDealerSrp: PropTypes.bool,
  showInTransit: PropTypes.bool,
  photoUrl: PropTypes.string,
  isInStockOnline: PropTypes.bool,
  isShop3181Chal: PropTypes.bool,
};

ContactDealerSrpUI.defaultProps = {
  tabIndex: 0,
  vinData: {},
  isMobile: false,
  isStickySection: false,
  rootClassName: '',
  featureFlags: {},
  pathname: '',
  radius: undefined,
  isNational: false,
  buttonColor: '',
  isNonTMVDealer: false,
  showLeasePayments: false,
  isPagePrimaryButton: false,
  tcpaParentDealershipName: null,
  isSimilarCards: false,
  ariaLabel: null,
  isDealerSrp: false,
  showInTransit: false,
  photoUrl: '',
  isInStockOnline: false,
  isShop3181Chal: false,
};

export const mapStateToProps = state => ({
  featureFlags: state.featureFlags,
});

export const stateToPropsConfig = {
  pathname: bindToPath('location.pathname', PageModel),
};

export const ContactDealerSrp = connect(mapStateToProps)(connectToModel(ContactDealerSrpUI, stateToPropsConfig));
