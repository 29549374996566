import { get } from 'lodash';
import classNames from 'classnames';
/* Constants */
import {
  CTA_TYPE,
  CTA_TEXT,
  SEPARATELY_DISPLAYED_CTAS,
  CTA_SIZE,
  EXCLUDED_FROM_MOBILE,
  LEAD_FORM_DRAWER_OPENING_CTAS,
} from 'site-modules/shared/constants/inventory/lead-form';
import { LEAD_FORM_NAMES } from 'site-modules/shared/constants/lead-form/lead-form-names';
import { TrackingConstant } from 'client/tracking/constant';
import { NOT_STRIKETHROUGH_MAKES } from 'client/constants/make-exclusions';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';
/* Utils */
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { getVdpUrl } from 'site-modules/shared/utils/inventory-utils/get-vdp-url';
import { getUnifiedLeadFormByName } from 'site-modules/shared/components/lead-form/unified-lead-form/unified-lead-form-wrapper';
import {
  getInventoryCta,
  CONTACT_OPTION_BY_CTA_NAME as DigitalRetailCTAs,
} from 'site-modules/shared/utils/inventory/contact-options';
import { isUsedDealCheckAvailable } from 'site-modules/shared/utils/car-buying/is-used-deal-check-available';
import { getLeadFormCreativeId } from 'site-modules/shared/components/lead-form/utils/lead-form-creative-id';

/**
 * Forms CTA object for VDP/SRP pages.
 * @param {Object} cta
 * @param {Object} singleSelectCtas
 * @param {Boolean} isMobile
 * @param {Boolean} isSrp
 * @returns {Object}
 */
function formCTAObject(cta, isMobile, isSrp, singleSelectCtas) {
  const { type } = cta;
  const isSingleSelect = !!singleSelectCtas[type];
  const ctaUrl = get(cta, 'url', {});

  const base = ctaUrl.base;
  const multi = ctaUrl.multi;
  const url = (isSrp && !isSingleSelect && multi) || base;

  const ctaText = get(cta, 'text', {});
  const text = (isSrp && ctaText.srp) || ctaText.vdp;

  const leadFormVdp = get(cta, 'leadForm.vdp');
  const leadFormSrp = get(cta, `leadForm.srp.${isSingleSelect ? 'single' : 'multi'}`);
  const leadForm = isSrp ? leadFormSrp : leadFormVdp;

  const leadFormTrackingParentSrp = get(cta, `leadFormTrackingParent.srp.${isSingleSelect ? 'single' : 'multi'}`);
  const leadFormTrackingParentVdp = get(cta, 'leadFormTrackingParent.vdp');
  const leadFormTrackingParent = isSrp ? leadFormTrackingParentSrp : leadFormTrackingParentVdp;

  return { ...cta, text, url, leadForm, leadFormTrackingParent };
}

/**
 * Builds map of cta to lead form configuration
 * @param {Object} inventory
 * @param {string[]} ctas
 * @param {Object} [options]
 * @param {boolean} national
 * @param {string} pageName
 * @param {string} zipCode
 * @returns {Object}
 */
export function getLeadFormCtasConfigsMap({ inventory, ctas, options, national, pageName, zipCode }) {
  const {
    dealerInfo: { franchiseId, rooftopId, distance },
    vehicleInfo: {
      styleInfo: { make, model, year, subModels, trim, styleId, style, bodyType, specialIdentifier },
    },
    type,
    vin,
  } = inventory;
  const isTopEmbeddedLeadFormEnabled = get(options, 'isTopEmbeddedLeadFormEnabled', false);
  const phoneNumber = get(inventory, 'computedDisplayInfo.phoneNumber', '');
  const radius = get(options, 'radius', '');
  const deliveryCode = get(inventory, 'deliveryOptions.deliveryCode');
  const vdpUrl = getVdpUrl(inventory, national, true, { radius });
  const isPartnerListing = get(options, 'isPartnerListing', false);
  const isSrp = get(options, 'isSrp', false);
  const isNewVin = isNew(type);
  const isUsedDealCheckDataAvailable = isUsedDealCheckAvailable(inventory);

  const trackingData = {
    'data-dealer-franchise-id': franchiseId,
    'data-dealer-rooftop-id': rooftopId,
  };

  const submodel = get(subModels, '[0].name', '');
  const submodelIdentifier = get(subModels, '[0].identifier', '');

  const priceStrikethrough = get(inventory, 'computedDisplayInfo.priceStrikethrough', false);

  const parentDealershipName = get(inventory, 'dealerInfo.displayInfo.parentDealershipName', '');
  const pageNameLegacy = get(options, 'pageNameLegacy', '');

  const isNewLeadFormEnabled =
    isNewVin && isPartnerListing && ctas.length && ctas.some(cta => cta !== CTA_TYPE.CALL_DEALER);

  const isMultiDigitalRetailLeadForm =
    (isNewLeadFormEnabled || isUsedDealCheckDataAvailable || (!isNewVin && isPartnerListing)) && isSrp;

  const multiDigitalRetailLeadFormTrackingParent = isMultiDigitalRetailLeadForm
    ? getLeadFormCreativeId({
        isNewVin,
        pageNameLegacy: pageNameLegacy || pageName,
        formName: LEAD_FORMS_CREATIVE_ID.DEAL_CHECK_PRICE_MULTI_DRAWER,
      })
    : undefined;

  const checkAvailabilityLeadFormTrackingParent = isMultiDigitalRetailLeadForm
    ? getLeadFormCreativeId({
        isNewVin,
        pageNameLegacy: pageNameLegacy || pageName,
        formName: LEAD_FORMS_CREATIVE_ID.CHECK_AVAILABILITY,
      })
    : undefined;

  const singleLeadFormTrackingParent = getLeadFormCreativeId({
    isNewVin,
    pageNameLegacy: pageName,
    formName: LEAD_FORMS_CREATIVE_ID.DEALER_FORM_SRP,
  });

  const isUsedSrpDealCheck = (isUsedDealCheckDataAvailable || (!isNewVin && isPartnerListing)) && isSrp;

  const configParams = {
    vin,
    make,
    model,
    year,
    styleId,
    style,
    submodel,
    submodelIdentifier,
    trim,
    specialIdentifier,
    bodyType,
    zipCode,
    radius,
    pageName,
    priceStrikethrough,
    pageNameLegacy,
    isNewVin,
    dealerName: parentDealershipName,
    isNewVinOnSrp: isNewVin && isSrp,
    isUsedSrpDealCheck,
    creativeId: isUsedSrpDealCheck ? checkAvailabilityLeadFormTrackingParent : multiDigitalRetailLeadFormTrackingParent,
    type,
    distance,
    deliveryCode,
  };

  const [nciSrp, multiDigitalRetailLeadFormSrpUsed, multiDigitalRetailLeadFormSrpNew, nciVdp] = [
    LEAD_FORM_NAMES.NCI_SRP,
    LEAD_FORM_NAMES.MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_USED,
    LEAD_FORM_NAMES.MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_NEW,
    LEAD_FORM_NAMES.NCI,
  ].map(leadFormName =>
    getUnifiedLeadFormByName(
      leadFormName,
      {
        ...configParams,
        ...(leadFormName === LEAD_FORM_NAMES.MULTI_DIGITAL_RETAIL_OFFER_TYPE_SRP_NEW
          ? { priceStrikethrough: !NOT_STRIKETHROUGH_MAKES.includes(make) }
          : {}),
      },
      options
    )
  );
  const isShop3181Chal = get(options, 'isShop3181Chal', false);

  return {
    [CTA_TYPE.NEW.BASE]: {
      text: {
        vdp: CTA_TEXT.GET_DEALER_OFFER,
        srp: isShop3181Chal ? CTA_TEXT.CHECK_PRICING : CTA_TEXT.GET_DEALERS_BEST_PRICE,
      },
      leadForm: {
        vdp: nciVdp,
        srp: {
          single: nciSrp,
          multi: multiDigitalRetailLeadFormSrpNew,
        },
      },
      leadFormTrackingParent: {
        vdp: undefined,
        srp: {
          single: singleLeadFormTrackingParent,
          multi: multiDigitalRetailLeadFormTrackingParent,
        },
      },
    },
    [CTA_TYPE.USED.BASE]: {
      text: {
        vdp: isTopEmbeddedLeadFormEnabled ? CTA_TEXT.CHECK_AVAILABILITY : CTA_TEXT.IM_INTERESTED,
        srp: CTA_TEXT.CHECK_AVAILABILITY,
      },
      leadForm: {
        vdp: getUnifiedLeadFormByName(LEAD_FORM_NAMES.UCI, configParams, options),
        srp: {
          single: multiDigitalRetailLeadFormSrpUsed,
          multi: multiDigitalRetailLeadFormSrpUsed,
        },
      },
      leadFormTrackingParent: {
        vdp: undefined,
        srp: {
          single: checkAvailabilityLeadFormTrackingParent,
          multi: checkAvailabilityLeadFormTrackingParent,
        },
      },
    },
    [CTA_TYPE.CALL_DEALER]: {
      text: {
        vdp: CTA_TEXT.CALL_DEALER,
      },
      url: {
        base: `tel:${phoneNumber}`,
      },
      isLink: true,
      firstClickText: phoneNumber,
      'data-tracking-id': 'vin_call_dealer',
      ...trackingData,
    },
    [CTA_TYPE.DETAILS]: {
      text: {
        srp: CTA_TEXT.DETAILS,
      },
      url: {
        base: vdpUrl,
      },
      rel: 'nofollow',
      isLink: true,
    },
  };
}

/**
 * Takes an array of ctas and attaches configurations for lead forms
 * @param {Object} inventory
 * @param {string[]} ctas
 * @param {Object} [options]
 * @param {boolean} national
 * @param {string} pageName
 * @param {string} zipCode
 * @param {Function} [ctasConfigMapFactory] - Factory, which returns config map for each cta
 * @returns {object[]}
 */
export function mapLeadFormCTAsToConfig({
  inventory,
  ctas,
  options,
  national,
  pageName,
  zipCode,
  ctasConfigMapFactory = getLeadFormCtasConfigsMap,
}) {
  const singleSelectCtas = get(inventory, 'computedDisplayInfo.leadFormDisplayInfo.singleSelect', {});
  const isMobile = get(options, 'isMobile', false);
  const isSrp = get(options, 'isSrp', false);

  const ctaMapping = ctasConfigMapFactory({
    inventory,
    ctas,
    options,
    national,
    pageName,
    zipCode,
  });

  return ctas.reduce((result, cta) => {
    // Checks that we have no separately displayed ctas
    const hasNoSeparatelyDisplayedCtas = !SEPARATELY_DISPLAYED_CTAS.includes(cta);

    // Checks that we have no excluded ctas, e.g. `callDealer` on mobile, because for mobile `callDealer` button
    // renders even if cta `callDealer` doesn't come.
    const hasNoExclusions = isMobile
      ? hasNoSeparatelyDisplayedCtas && !EXCLUDED_FROM_MOBILE.includes(cta)
      : hasNoSeparatelyDisplayedCtas;

    if (hasNoExclusions) {
      result.push(formCTAObject({ ...ctaMapping[cta], type: cta }, isMobile, isSrp, singleSelectCtas));
    }
    return result;
  }, []);
}

/**
 * Maps passed CTA name to CTA config object.
 * =======================================================================================
 * @param {Object} inventory
 * @param {String} pageName
 * @param {String} zipCode
 * @param {Boolean} national
 * @param {Object} [options] Map of other objects require passing
 * @param {Function} [ctasConfigMapFactory] Provides config map
 * @returns {object[]}
 */
export function getLeadFormCTAs(
  inventory,
  pageName,
  zipCode,
  options,
  national,
  ctasConfigMapFactory = getLeadFormCtasConfigsMap
) {
  let ctas = get(inventory, 'computedDisplayInfo.ctas', []);

  const isSrp = get(options, 'isSrp', false);
  const skipDRBtn = get(options, 'skipDRBtn', false);

  ctas = getInventoryCta({
    ctas,
    skip: skipDRBtn,
    isSrp,
    inventory,
  });

  return mapLeadFormCTAsToConfig({
    inventory,
    options,
    national,
    ctas,
    pageName,
    zipCode,
    ctasConfigMapFactory,
  });
}

/**
 * Gets CTA with needed properties to show.
 * @param {Array} ctas Lead form CTAs configs
 * @param {Boolean} isMobile
 * @param {Boolean} isSrp
 * @param {Object} vinData
 * @param {Object} displayOptions
 * @param {Object} embeddedLeadFormOptions
 * @param {Object} newPriceValidationOptions
 * @returns {Array}
 */
export function getCTAToDisplay(ctas, isMobile, isSrp, vinData, displayOptions, embeddedLeadFormOptions) {
  const { buttonSize, reverseOrder, onlyOnePrimary, buttonSecondarySize } = displayOptions;
  const franchiseId = get(vinData, 'dealerInfo.franchiseId', '');
  const rooftopId = get(vinData, 'dealerInfo.rooftopId', '');
  const trackingData = {
    'data-dealer-franchise-id': franchiseId,
    'data-dealer-rooftop-id': rooftopId,
  };

  // Add tracking data to ctas
  let ctaToDisplay = ctas.map(cta => ({
    trackingId: TrackingConstant.VIEW_PRICING_DETAILS,
    ...cta,
    ...trackingData,
    trackingValue: get(cta, 'text', ''),
    isPrimary: true,
  }));

  // use only digital retail CTAs
  const { skipCBPButton, isTopEmbeddedLeadFormEnabled } = embeddedLeadFormOptions;
  const useOnlyDigitalRetailCTAsWithEmbeddedForm = isTopEmbeddedLeadFormEnabled && !skipCBPButton && !isSrp;
  if (useOnlyDigitalRetailCTAsWithEmbeddedForm) {
    ctaToDisplay = ctaToDisplay.filter(cta => cta.type && !!DigitalRetailCTAs[cta.type]);
  }

  const ctasLength = ctaToDisplay.length;

  const dealerPhone = get(vinData, 'computedDisplayInfo.phoneNumber', '');

  // Show `Call Dealer` on mobile VDP if dealer phone is defined and count of buttons less than 2
  // and there's no embedded lead form
  if (!isSrp && !useOnlyDigitalRetailCTAsWithEmbeddedForm && isMobile && dealerPhone && ctasLength < 2) {
    ctaToDisplay.unshift({
      iconClasses: 'icon icon-phone2 pr-0_25',
      url: `tel:${dealerPhone}`,
      isLink: true,
      type: CTA_TYPE.CALL_DEALER,
      text: CTA_TEXT.CALL_DEALER,
      'data-tracking-id': 'vin_call_dealer',
      ...trackingData,
    });
  }

  // Add columnSizes and classes to ctas
  if (isSrp) {
    return ctaToDisplay.map(cta => ({
      ...cta,
      columnSizes: { xs: CTA_SIZE.FULL },
      columnClassName: 'px-0',
      size: 'sm',
    }));
  }
  const callDealerSize = isMobile && !ctasLength && CTA_SIZE.CALL_DEALER;
  const isFewCtasToDisplay = ctaToDisplay.length > 1;
  const xsColumnSize = callDealerSize || (isFewCtasToDisplay ? CTA_SIZE.HALF : CTA_SIZE.FULL_CENTERED);
  const mdColumnSize = callDealerSize || CTA_SIZE.FULL_CENTERED;
  const columnSizes = useOnlyDigitalRetailCTAsWithEmbeddedForm
    ? { xs: CTA_SIZE.FULL }
    : { xs: xsColumnSize, md: mdColumnSize };

  return (reverseOrder ? ctaToDisplay.reverse() : ctaToDisplay).map((cta, index) => ({
    ...cta,
    columnSizes,
    columnClassName: classNames('px-0_25', { 'mb-md-1': isFewCtasToDisplay && index === 0 }),
    isExternalButton: onlyOnePrimary && index !== 0,
    size: (onlyOnePrimary && index !== 0 ? buttonSecondarySize : buttonSize) || 'lg',
  }));
}

/**
 * Checks if there's a CTA that opens Lead Form Drawer
 * @param {Array} ctas
 * @returns {Boolean}
 */
export function canShowLeadFormDrawer(ctas) {
  return !!ctas.length && ctas.some(btnType => LEAD_FORM_DRAWER_OPENING_CTAS.includes(btnType));
}
