import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { Row, Col } from 'reactstrap';

// Models
import { InventoryEntities } from 'client/data/models/inventory';
import { VisitorEntities } from 'client/data/models/visitor';

// Utils
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { getUsurpImages } from 'site-modules/shared/utils/inventory/srp-images';
import { getPhotoProvider } from 'site-modules/shared/utils/inventory-image-utils';
import {
  getVdpLink,
  getPaymentDetails,
  getDisplayBelowMarketCopy,
  getVehicleName,
  transformDerivedFeatures,
  getKeyPoints,
  getSimilarCardsKeyPoints,
  getLlmCardKeyPoints,
  getVehicleNameAriaLabel,
  getIsLeaseCardWithLeasePayment,
  getIsLoanCardWithLoanPayment,
  getTCPAParentDealershipName,
  isUsedCTAAvailable,
  isCTAAvailable,
} from 'site-modules/shared/utils/inventory/usurp-inventory-card';
import { isInstockOnline, isDriveway, isDeliveryEligible } from 'site-modules/shared/utils/dealer-details-utils';
import { isRecurrentAvailable } from 'client/site-modules/shared/utils/inventory/is-recurrent-available';

// Constants
import { LEASE_PAYMENT } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { DEFAULT_COLOR } from 'site-modules/shared/components/inventory/constants/usurp-inventory-card';

// Components
import { ColorInfo } from 'site-modules/shared/components/inventory/color-info/color-info';
import { ContactDealerSrp } from 'site-modules/shared/components/inventory/contact-dealer/contact-dealer-srp';
import { PricingDetails } from 'site-modules/shared/components/usurp-inventory-card/pricing-details';
import { UsurpInventoryCardPhoto } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-photo';
import { RecurrentInsights } from 'client/site-modules/shared/components/inventory/recurrent-insights/recurrent-insights';
import { TopFeatureBubbles } from 'site-modules/shared/components/inventory/top-feature-bubbles/top-feature-bubbles';
import { UsurpInventoryCardInfo } from 'site-modules/shared/components/inventory/usurp-inventory-card-info/usurp-inventory-card-info';
import { VehicleNameDisplay } from 'site-modules/shared/components/inventory/vehicle-name-display/vehicle-name-display';
import { UsurpCardViewMore } from 'site-modules/shared/components/inventory/usurp-card-view-more/usurp-card-view-more';
import { UsurpCardKeyPoint } from 'site-modules/shared/components/inventory/usurp-card-key-point/usurp-card-key-point';
import { ClickableCard } from 'site-modules/shared/components/inventory/clickable-card/clickable-card';
import { ClickableCardLink } from 'site-modules/shared/components/inventory/clickable-card/clickable-card-link/clickable-card-link';
import { UsurpInventoryCardVdpLink } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-vdp-link';

import './usurp-inventory-card.scss';

export function KeyPointSimilarCard({ icon, iconTitle, content, classes }) {
  return (
    <Fragment>
      <span title={iconTitle} className={classnames(icon, classes, 'key-point-icon d-inline-block size-12 mr-0_5')} />
      {content}
    </Fragment>
  );
}

KeyPointSimilarCard.propTypes = {
  icon: PropTypes.string.isRequired,
  iconTitle: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  classes: PropTypes.string.isRequired,
};

export class UsurpInventoryCard extends PureComponent {
  static propTypes = {
    vehicle: InventoryEntities.InventoryVin.isRequired,
    visitorLocation: VisitorEntities.Location,
    radius: PropTypes.number.isRequired,
    className: PropTypes.string,
    paymentType: PropTypes.string,
    selectedPaymentType: PropTypes.string,
    isNational: PropTypes.bool,
    isMobile: PropTypes.bool,
    disableInventoryMLPhotoSort: PropTypes.bool,
    clientSideRenderImage: PropTypes.bool,
    lazyLoadImage: PropTypes.bool,
    importance: PropTypes.string,
    isSimilarCards: PropTypes.bool,
    isLlmCard: PropTypes.bool,
    grayIcons: PropTypes.bool,
    isEnhancedName: PropTypes.bool,
    showNationalDelivery: PropTypes.bool,
    isVdpPartnerListing: PropTypes.bool,
    isViewed: PropTypes.bool,
    isDealerSrp: PropTypes.bool,
    isExpandedRadius: PropTypes.bool,
    showCtaButton: PropTypes.bool,
    showInventoryHeart: PropTypes.bool,
    showInventoryBadge: PropTypes.bool,
    tabIndex: PropTypes.number,
    isSrp: PropTypes.bool,
    searchId: PropTypes.string,
    isShop2901Enabled: PropTypes.bool,
    disableDrivewayNewCarLeadForms: PropTypes.bool,
    isShop3181Chal: PropTypes.bool,
    isShop3165Enabled: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    visitorLocation: {},
    paymentType: '',
    selectedPaymentType: '',
    isNational: false,
    isMobile: false,
    disableInventoryMLPhotoSort: false,
    clientSideRenderImage: false,
    lazyLoadImage: false,
    importance: null,
    isSimilarCards: false,
    isLlmCard: false,
    grayIcons: false,
    isEnhancedName: false,
    showNationalDelivery: false,
    isVdpPartnerListing: false,
    isViewed: false,
    isDealerSrp: false,
    isExpandedRadius: false,
    showCtaButton: true,
    showInventoryHeart: true,
    showInventoryBadge: true,
    tabIndex: 0,
    isSrp: false,
    searchId: undefined,
    isShop2901Enabled: false,
    disableDrivewayNewCarLeadForms: false,
    isShop3181Chal: false,
    isShop3165Enabled: false,
  };

  getCtaElement = (ariaLabel, photoUrls) => {
    const {
      vehicle,
      radius,
      isNational,
      isMobile,
      selectedPaymentType,
      isSimilarCards,
      isVdpPartnerListing,
      isDealerSrp,
      showCtaButton,
      tabIndex,
      isSrp,
      visitorLocation,
      isShop3181Chal,
    } = this.props;
    const { type, dealerInfo } = vehicle;

    const isNewType = isNew(type);
    const isLeaseCard = selectedPaymentType === LEASE_PAYMENT;
    const parentDealershipName = get(vehicle, DATA_PATH.PARENT_DEALERSHIP_NAME, '');
    const isDrivewayDealer = isDriveway(parentDealershipName);
    const tcpaParentDealershipName = getTCPAParentDealershipName({ parentDealershipName });
    const distance = Math.round(get(dealerInfo, 'distance', 0));
    const zipCode = get(visitorLocation, 'zipCode');

    const isInStockOnline =
      isNewType &&
      isDrivewayDealer &&
      isInstockOnline({ distance, radius, isDeliveryAvailable: isDeliveryEligible(vehicle, zipCode) });

    if (isVdpPartnerListing) {
      return null;
    }

    if (isNewType) {
      return (
        <ContactDealerSrp
          vinData={vehicle}
          tabIndex={tabIndex}
          rootClassName={classnames('no-gutters margin-top-auto pt-1', {
            'd-block': showCtaButton,
            'd-none': !showCtaButton,
          })}
          radius={radius}
          isNational={isNational}
          isMobile={isMobile}
          showLeasePayments={isLeaseCard}
          buttonColor={classnames('srp-cta text-capitalize size-16 fw-medium', {
            'px-1': !isSimilarCards,
            'px-0_75': isSimilarCards,
          })}
          isSimilarCards={isSimilarCards}
          ariaLabel={ariaLabel}
          isDealerSrp={isDealerSrp}
          showInTransit={isSrp}
          photoUrl={photoUrls[0]}
          isInStockOnline={isInStockOnline}
          isShop3181Chal={isShop3181Chal}
        />
      );
    }

    if (isUsedCTAAvailable({ vehicle })) {
      return (
        <ContactDealerSrp
          vinData={vehicle}
          rootClassName={classnames('no-gutters margin-top-auto pt-1', {
            'd-block': showCtaButton,
            'd-none': !showCtaButton,
          })}
          tabIndex={tabIndex}
          radius={radius}
          isNational={isNational}
          isMobile={isMobile}
          showLeasePayments={isLeaseCard}
          tcpaParentDealershipName={tcpaParentDealershipName}
          buttonColor="srp-cta text-capitalize fw-medium size-16 px-1"
          isSimilarCards={isSimilarCards}
          ariaLabel={ariaLabel}
          isDealerSrp={isDealerSrp}
          photoUrl={photoUrls[0]}
        />
      );
    }

    return null;
  };

  render() {
    const {
      className,
      vehicle,
      visitorLocation,
      radius,
      paymentType,
      isNational,
      isMobile,
      selectedPaymentType,
      disableInventoryMLPhotoSort,
      clientSideRenderImage,
      lazyLoadImage,
      importance,
      isSimilarCards,
      isLlmCard,
      grayIcons,
      isEnhancedName,
      showNationalDelivery,
      isVdpPartnerListing,
      isViewed,
      isExpandedRadius,
      showInventoryHeart,
      showInventoryBadge,
      tabIndex,
      isSrp,
      searchId,
      isShop2901Enabled,
      disableDrivewayNewCarLeadForms,
      isShop3165Enabled,
    } = this.props;
    const { type, vehicleInfo, dealerInfo, prices, vin } = vehicle;
    const savings = get(vehicle, 'computedDisplayInfo.priceValidation.priceDiscount', 0);
    const exteriorColor = get(vehicleInfo, 'vehicleColors.exterior');
    const exteriorColorName = get(exteriorColor, 'name', DEFAULT_COLOR);
    const interiorColor = get(vehicleInfo, 'vehicleColors.interior');
    const interiorColorName = get(interiorColor, 'name', DEFAULT_COLOR);
    const isNewType = isNew(type);

    const dealerAddress = get(dealerInfo, 'address');
    const dealerStateCode = get(dealerAddress, 'stateCode');
    const { photoUrls, hasPhotos, photoType } = getUsurpImages(vehicle, disableInventoryMLPhotoSort);

    const vehicleName = getVehicleName(vehicle, isEnhancedName);

    const isGrayIcons = grayIcons || isSimilarCards;
    const cgfFeatures = get(vehicleInfo, 'partsInfo.cgf', []);
    const topFeatures = cgfFeatures && cgfFeatures.length ? cgfFeatures : transformDerivedFeatures(vehicle);
    const rooftopId = get(vehicle, 'dealerInfo.rooftopId', '');

    const isElectric = get(vehicle, 'vehicleInfo.partsInfo.engineType') === 'electric';
    const isRecurrent = isRecurrentAvailable(vehicle);
    const showRecurrentData = isSrp && isElectric && !isNewType && isRecurrent;

    const keyPoints = getKeyPoints({
      vehicle,
      visitorLocation,
      radius,
      isNational,
      showNationalDelivery,
      isExpandedRadius,
      isSrp,
      showRecurrentData,
      isGrayIcons,
      isShop3165Enabled,
    });
    const keyPointsSimilarCards = isLlmCard
      ? getLlmCardKeyPoints({ vehicle })
      : getSimilarCardsKeyPoints({
          vehicle,
          visitorLocation,
          radius,
          isNational,
          showNationalDelivery,
          isSrp,
          isGrayIcons,
        });

    const { paymentValue } = getPaymentDetails({
      prices,
      usePaymentType: isNewType,
      selectedPaymentType,
      paymentType,
    });
    const displayBelowMarketCopy = getDisplayBelowMarketCopy({
      paymentValue,
      isNew: isNewType,
      dealerStateCode,
      savings,
    });
    const vdpLink = getVdpLink({ vehicle, isNational, paymentType, radius });

    const isLeaseCardWithLeasePayment = getIsLeaseCardWithLeasePayment({ selectedPaymentType, prices });
    const isLoanCardWithLoanPayment = getIsLoanCardWithLoanPayment({ selectedPaymentType, prices });
    const SRP_CARD_PHOTO_LIMIT = 15;

    let vehicleNameDisplay = vehicleName;

    if (!isSimilarCards) {
      const [firstLine, secondLine] = getVehicleName(vehicle, isEnhancedName, true);
      vehicleNameDisplay = <VehicleNameDisplay vehicle={vehicle} firstLine={firstLine} secondLine={secondLine} />;
    }

    const ariaLabel = getVehicleNameAriaLabel({ isSimilarCards, vehicle, isEnhancedName, prices, selectedPaymentType });
    const showCta = isCTAAvailable({ vehicle, disableDrivewayNewCarLeadForms });
    const ctaElement = showCta ? this.getCtaElement(ariaLabel, photoUrls) : null;

    return (
      <ClickableCard
        className={classnames('d-flex flex-column usurp-inventory-card overflow-hidden h-100', className, {
          'bg-white rounded': isSimilarCards,
          'w-100 rounded-12 bg-white': !isSimilarCards,
          'semantic-search-card': isLlmCard,
        })}
        data-dealer-rooftop-id={rooftopId}
      >
        <Fragment>
          <UsurpInventoryCardPhoto
            photos={photoUrls.slice(0, SRP_CARD_PHOTO_LIMIT)}
            hasPhotos={hasPhotos}
            provider={getPhotoProvider(vehicle)}
            vehicle={vehicle}
            visitorStateCode={visitorLocation.stateCode}
            isMobile={isMobile}
            vin={vin}
            photoType={photoType}
            vdpLink={vdpLink}
            clientSideRenderImage={clientSideRenderImage}
            lazyLoadImage={lazyLoadImage}
            importance={importance}
            isSimilarCards={isSimilarCards}
            isSrpExpanded={!isSimilarCards}
            isViewed={isViewed}
            showInventoryHeart={showInventoryHeart}
            showInventoryBadge={showInventoryBadge}
            tabIndex={tabIndex}
            showInTransit={isSrp}
            heartAriaLabel={ariaLabel}
            isLlmCard={isLlmCard}
            searchId={searchId}
            isVdpPartnerListing={isVdpPartnerListing}
            isShop2901Enabled={isShop2901Enabled}
          />
          <div
            className={classnames('vehicle-info d-flex flex-column p-1', {
              'justify-content-between': isSimilarCards,
            })}
          >
            <div className={classnames('visible-vehicle-info d-flex flex-column')}>
              {isSimilarCards && (
                <div className="d-flex align-items-center justify-content-between">
                  {isLeaseCardWithLeasePayment ? (
                    <PricingDetails
                      vehicle={vehicle}
                      displayBelowMarketCopy={displayBelowMarketCopy}
                      displayLeaseMonthlyPayment
                      displayDownPayment
                      displayDealBadge={false}
                      priceClasses={classnames({ 'heading-4': !isLlmCard })}
                    />
                  ) : (
                    <PricingDetails
                      vehicle={vehicle}
                      displayBelowMarketCopy={(isNewType || !isLoanCardWithLoanPayment) && displayBelowMarketCopy}
                      displayDealBadge={false}
                      displayLoanMonthlyPayment={isLoanCardWithLoanPayment}
                      displayMSRPCopyRight={(isNewType && !selectedPaymentType) || isLlmCard}
                      noPriceClasses=""
                      priceClasses={classnames({ 'heading-4': !isLlmCard })}
                    />
                  )}
                </div>
              )}
              {isSimilarCards && (
                <h2 className="card-title size-16 text-primary-darker fw-bold d-block mb-0_5 mt-0_25">
                  <ClickableCardLink
                    linkComponent={UsurpInventoryCardVdpLink}
                    className="wrapping-vdp-link"
                    vehicle={vehicle}
                    vdpLink={vdpLink}
                    isSimilarCards={isSimilarCards}
                    isLlmCard={isLlmCard}
                    searchId={searchId}
                    isVdpPartnerListing={isVdpPartnerListing}
                    tabIndex={tabIndex}
                    ariaLabel={ariaLabel}
                  >
                    {vehicleName}
                  </ClickableCardLink>
                </h2>
              )}
              {!isSimilarCards && (
                <UsurpInventoryCardInfo
                  vehicle={vehicle}
                  selectedPaymentType={selectedPaymentType}
                  ariaLabel={ariaLabel}
                  vehicleNameDisplay={vehicleNameDisplay}
                  vdpLink={vdpLink}
                  displayBelowMarketCopy={displayBelowMarketCopy}
                  isLeaseCardWithLeasePayment={isLeaseCardWithLeasePayment}
                  isLoanCardWithLoanPayment={isLoanCardWithLoanPayment}
                  isShop2901Enabled={isShop2901Enabled}
                />
              )}
              <Row className={classnames({ 'text-gray-darker': !isSimilarCards }, 'list-unstyled mb-0')} tag="ul">
                {isNewType && !isLlmCard ? (
                  <Fragment>
                    <Col style={{ lineHeight: '1.125rem' }} className="col-auto pr-0" tag="li">
                      {exteriorColorName !== DEFAULT_COLOR && (
                        <ColorInfo
                          color={exteriorColor}
                          colorName={exteriorColorName}
                          colorSide="Exterior"
                          {...(!isSimilarCards
                            ? {
                                label: exteriorColorName,
                                style: {
                                  marginRight: '1rem',
                                  marginTop: '0.5rem',
                                  display: 'inline-block',
                                  lineHeight: '1.125rem',
                                },
                                isSrpExpanded: true,
                              }
                            : {
                                label: 'Exterior',
                              })}
                        />
                      )}
                    </Col>
                    <Col style={{ lineHeight: '1.125rem' }} className="col-auto" tag="li">
                      {interiorColorName !== DEFAULT_COLOR && (
                        <ColorInfo
                          color={interiorColor}
                          colorName={interiorColorName}
                          colorSide="Interior"
                          {...(!isSimilarCards
                            ? {
                                label: interiorColorName,
                                style: { marginTop: '0.5rem', display: 'inline-block', lineHeight: '1.125rem' },
                                isSrpExpanded: true,
                              }
                            : {
                                label: 'Interior',
                              })}
                        />
                      )}
                    </Col>
                    {!isSimilarCards ? (
                      <UsurpCardKeyPoint
                        keyPoints={keyPoints}
                        isGrayIcons={isGrayIcons}
                        tag={Col}
                        columnSizes={{ xs: 12 }}
                        htmlTag="li"
                      />
                    ) : (
                      keyPointsSimilarCards.map(point => (
                        <Col xs={12} tag="li" key={point.iconTitle}>
                          <div className="size-14 mt-0_25">
                            <KeyPointSimilarCard
                              {...point}
                              classes={classnames({
                                'text-gray-darker': isGrayIcons,
                                'text-primary-darker': !isGrayIcons,
                              })}
                            />
                          </div>
                        </Col>
                      ))
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {!isSimilarCards ? (
                      <UsurpCardKeyPoint
                        tag={Col}
                        columnSizes={{ xs: 12 }}
                        keyPoints={keyPoints}
                        isGrayIcons={isGrayIcons}
                        htmlTag="li"
                      />
                    ) : (
                      keyPointsSimilarCards.map(point => (
                        <Col xs={12} key={point.iconTitle} className="size-14 mt-0_25" tag="li">
                          <KeyPointSimilarCard
                            {...point}
                            classes={classnames({
                              'text-gray-darker': isGrayIcons,
                              'text-primary-darker': !isGrayIcons,
                            })}
                          />
                        </Col>
                      ))
                    )}
                  </Fragment>
                )}
              </Row>
              {!isSimilarCards && <TopFeatureBubbles topFeatures={topFeatures} className="mt-1" />}
              {showRecurrentData && !isShop3165Enabled && <RecurrentInsights vehicle={vehicle} className="mt-1" />}
              {!isSimilarCards && ctaElement}
            </div>
            {isSimilarCards && <div>{ctaElement}</div>}
            {!isSimilarCards && (
              <UsurpCardViewMore
                vehicle={vehicle}
                vehicleName={vehicleName}
                visitorLocation={visitorLocation}
                isLeaseCardWithLeasePayment={isLeaseCardWithLeasePayment}
              />
            )}
          </div>
        </Fragment>
      </ClickableCard>
    );
  }
}
